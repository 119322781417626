import React from 'react';

import { cn } from '../../utils';
import Image from '../Image/Image';
import PlayButton from '../PlayButton/PlayButton';
import Quote from '../Quote/Quote';
import Paragraph from '../Typography/Paragraph/Paragraph';
import * as styles from './TestimonialSlide.module.scss';
import ITestimonialSlide from './TestimonialSlide.type';

const TestimonialSlide = (props: ITestimonialSlide) => (
    <>
        <div className={styles.thumbnail}>
            <Image {...props.thumbnail} className={styles.img} />
            <div className={cn(styles.content, (!props.videoId && !props.quote) && styles["centered"])}>
                {props.videoId && <PlayButton
                    type="small"
                    onClick={() => {
                        props.setVideoId(props.videoId);
                        props.showPopup();
                    }}
                    className={styles.playButton}
                />}
                <Paragraph color="white">{props.name}</Paragraph>
            </div>
        </div>
        {props.quote && <Quote className={cn(styles.quote, "quote")} color="white">
            {props.quote}
        </Quote>}
    </>
);

export default TestimonialSlide;
