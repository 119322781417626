import React from 'react';

import QuotationMarkIcon from '../../images/quote.svg';
import { cn } from '../../utils';
import Paragraph from '../Typography/Paragraph/Paragraph';
import * as styles from './Quote.module.scss';
import IQuote from './Quote.type';

const Quote = ({ children, className, ...props }: IQuote) => {
    return (
        <div className={cn(styles.element, className)}>
            <img alt="quotation mark" src={QuotationMarkIcon} className={styles.quotationMark} />
            <Paragraph {...props}>{children}</Paragraph>
        </div>
    );
};

export default Quote;
