import 'swiper/css';
import 'swiper/css/pagination';

import React, { useState } from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import PopupVideo from '../../components/PopupVideo/PopupVideo';
import TestimonialSlide from '../../components/TestimonialSlide/TestimonialSlide';
import { cn } from '../../utils';
import * as styles from './TestimonialVideos.module.scss';
import ITestimonialVideos from './TestimonialVideos.type';

const TestimonialVideos = (props: ITestimonialVideos) => {
    const [videoId, setVideoId] = useState("");
    const [showPopup, setShowPopup] = useState(false);

    return (
        <section>
            <div className={cn("container", styles.container)}>
                <Swiper
                    className={styles.videos}
                    modules={[Pagination]}
                    pagination={{ clickable: true }}
                    slidesPerView={"auto"}
                    breakpoints={{
                        768: {
                            enabled: false,
                        },
                    }}
                >
                    {props.videos.map((video) => (
                        <SwiperSlide key={video.name} className={styles.slide}>
                            <TestimonialSlide
                                {...video}
                                setVideoId={setVideoId}
                                showPopup={() => setShowPopup(true)}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
            <PopupVideo
                videoId={videoId}
                showPopup={showPopup}
                closePopupFn={() => {
                    setShowPopup(false);
                }}
            />
        </section>
    );
};

export default TestimonialVideos;
